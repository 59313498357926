import {ActionTypes} from '../types'
const INITIAL_STATE={
    token:'',
    user_id:'',
    user_name:'',
    mobile:'',
    createdAt:'',
    email:'',
    role:'',
    is_subscribed:'',
    expire_at:'',
    subscriptionExpr:'',
    cancel_at:''
}

const sessionReducer=(state=INITIAL_STATE,action)=>{
 
    switch(action.type){
        case ActionTypes.STORE_SESSION:
            return {...state,token:action.payload.token,user_name:action.payload.user_name , role:action.payload.role, expire_at:action.payload.expire_at ,
                user_id:action.payload.user_id,mobile:action.payload.mobile,email:action.payload.email,createdAt:action.payload.createdAt,is_subscribed:action.payload.is_subscribed,subscriptionExpr:action.payload.subscriptionExpr,cancel_at:action.payload.cancel_at
            }
        case ActionTypes.REMOVE_SESSION:
            return {...state,token:'',user_id:'', user_name:'',role:'', expire_at:'', is_subscribed:'',email:'',createdAt:'',mobile:'',subscriptionExpr:'',cancel_at:''}
        case ActionTypes.SET_USER_DATA:
            return {...state,user_name:action.payload.user_name,mobile:action.payload.mobile,email:action.payload.email}
        case ActionTypes.SET_SUB_STATUS_DATA:
            return {...state,is_subscribed:action.payload}
            
        default:
            return  state;
    }
}

export default sessionReducer;

