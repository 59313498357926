import React,{useEffect,useState,lazy,Suspense} from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import Layout from "./components/layout";
import LogIN from './containers/login';
import { useDispatch } from 'react-redux'
import { addSessionData2 } from '../src/store/action/sessionAction'; 
import { addCompanyList ,addUSACompanyList ,getDateData,getUserSubStatus} from '../src/store/action/dataAction'; 
import {addBookList,getUserFilter} from '../src/store/action/filterAction';
import PrivateRoute from "./privateRoute";
import PrivateRoute2 from './privateRouteNonLogin'
import PublicRoute from './publicRoute';


import DocDownload from './containers/docs_download'
import UpdatesPage from './containers/update_page'
import ReactLoading from 'react-loading';
import  "./fonts/style.css";


const LazyComponent = (importStatement) => {
  const Component = lazy(importStatement);
  return () => (
    <Suspense fallback={<ReactLoading type={"spokes"} color={"#1c56ac"} className="loader" />}>
      <Component />
    </Suspense>
  );
}

const LandingPage = LazyComponent(() => import('./containers/landing_page'));
const Portfolio = LazyComponent(() => import('./containers/portfolio'));
const Home = LazyComponent(() => import('./containers/home'));
const FtmData = LazyComponent(() => import('./containers/ftm_data'));
const DailyData = LazyComponent(() => import('./containers/daily_data'));
const NotFound = LazyComponent(() => import('./containers/not_found'));
const TradingJournal = LazyComponent(() => import('./containers/journal'));
const AddNewJournal = LazyComponent(() => import('./containers/journal/addNewJournal'));
const Profile = LazyComponent(() => import('./containers/profile'));
const JournalDetails = LazyComponent(() => import('./containers/journal_details'));
const DailyDataChcekUsa = LazyComponent(() => import('./containers/data_check_daily_us'));
const PortfolioUsa = LazyComponent(() => import('./containers/portfolio _usa'));
const SharingCorner = LazyComponent(() => import('./containers/sharing_corner'));
const AddNewSharing = LazyComponent(() => import('./containers/sharing_corner/addNewSharing'));
const SharingDetails = LazyComponent(() => import('./containers/sharing_corner_details'));
const DailyDataUsa = LazyComponent(() => import('./containers/daily_data_us'));
const FtmDataUsa = LazyComponent(() => import('./containers/ftm_data_usa'));
const AleryMy = LazyComponent(() => import('./containers/alert_my'));
const Register = LazyComponent(() => import('./containers/register'));
const Subsription = LazyComponent(() => import('./containers/subscription'));
const PrivacyPolicy = LazyComponent(() => import('./containers/privacy_policy'));
const Terms = LazyComponent(() => import('./containers/terms'));
const ResetPassword = LazyComponent(() => import('./containers/reset_pass'));
const ForgotPass = LazyComponent(() => import('./containers/forgot_pass'));
const ManualAleryMy = LazyComponent(() => import('./containers/manual_alert_my'));
const WeeklyDataMy = LazyComponent(() => import('./containers/weekly_data_my'));
const SubCanceled = LazyComponent(() => import('./containers/sub_canceled'));
const SubSuccess = LazyComponent(() => import('./containers/sub-req'));
const WeeklyJdMy = LazyComponent(() => import('./containers/weekly_jd_my'));
const WeeklyDataUsa = LazyComponent(() => import('./containers/weekly_data_usa'));
const StatusCheck = LazyComponent(() => import('./containers/status_check'));
const Power5Check = LazyComponent(() => import('./containers/power5'));
const Power5CheckUsa = LazyComponent(() => import('./containers/power5_usa'));
const GalleryFolder = LazyComponent(() => import('./containers/gallery_folder'));
const Gallery = LazyComponent(() => import('./containers/gallery'));
const LandingPageAdmin = LazyComponent(() => import('./containers/landing_page_admin'));
const UserList = LazyComponent(() => import('./containers/user_list'));
const InOutLis=  LazyComponent(() => import('./containers/in_out_list'));
const RedirctLink = LazyComponent(() => import('./containers/redirect_url'));
const UsaList = LazyComponent(() => import('./containers/stock_list/us_list'));
const BursaList = LazyComponent(() => import('./containers/stock_list/my_list'));
const SharingComment = LazyComponent(() => import('./containers/sharing_comment'));
const JdAnalytic = LazyComponent(() => import('./containers/jd_analytics'));
const ManageTag = LazyComponent(() => import('./containers/manage_tag'));
const DocList = LazyComponent(() => import('./containers/doc_list'));
const ExcelData = LazyComponent(() => import('./containers/excel_data'));
const InsiderTrading = LazyComponent(() => import('./containers/insider_trading'));
const EarningPage = LazyComponent(() => import('./containers/earning'));
const LandingPageAnalytic = LazyComponent(() => import('./containers/landing_page_analytics'));
const Quiz=LazyComponent(() => import('./containers/quiz'));
const QuizQuestion=LazyComponent(() => import('./containers/quiz/quiz_question'));
const QuizPage=LazyComponent(() => import('./containers/quiz/quiz_page'));
const QuizAnalytics=LazyComponent(() => import('./containers/quiz/quiz_analytics'));
const BlogHeadline=LazyComponent(() => import('./containers/get_headline'));
const UserStat=LazyComponent(() => import('./containers/user_stats'));
const Notes=LazyComponent(() => import('./containers/notes'));
const ManageTagUser =LazyComponent(() => import('./containers/manage_tag_user'));

const ManageTagV2 = LazyComponent(() => import('./containers/manage_tag2'));
const UpdatesPageV2 = LazyComponent(() => import('./containers/update_page2'));
const DemoPage = LazyComponent(() => import('./containers/demo_page'));
const DemoPage2 = LazyComponent(() => import('./containers/demo_page2'));
// const Projects = LazyComponent(() => import('./containers/projects'));
function App() {
  const location = useLocation();
  const dispatch = useDispatch()
  
    useEffect(() => {
        dispatch(addSessionData2())
        dispatch(addCompanyList())
        dispatch(addBookList())
        dispatch(getUserFilter())
        dispatch(getUserSubStatus())
        dispatch(getDateData())
        dispatch(addUSACompanyList())
        // dispatch(getDailyData())
      try {
        const cookies = document.cookie.split('; ');
        console.log(cookies)
        if (!cookies.some(cookie => cookie.startsWith(`auth_token=`))) {
          const data =JSON.parse(localStorage.getItem('jwtToken'))
          console.log(data?.token)
          if (data) {
              document.cookie = `auth_token=${data.token}; max-age=${10 * 24 * 60 * 60}; path=/; secure`;
          }
        }
      } catch (e) { }
   
    }, [])
    
    document.body.style.backgroundColor = "#e3effd99";
    document.body.style.fontFamily = "Trebuchet MS,Trebuchet MS Italic";

  return (
    <Layout currentPath={location.pathname}>

        <Switch location={location} key={location.pathname}>
          <Route exact path={"/updates2"} component={UpdatesPageV2} /> 
          <Route exact path={"/manage_tag2"} component={ManageTagV2} /> 

          <Route exact path={"/"} component={DemoPage} /> 
          <Route exact path={"/demo1"} component={DemoPage} /> 
          <Route exact path={"/demo2"} component={DemoPage2} /> 
          {/* <Route exact path={"/projects"} component={Projects} />  */}
          <PrivateRoute2 exact path={"/screener"} component={DailyData} />
          <PrivateRoute exact path={"/screener/usa"} component={DailyDataUsa} />
          <PrivateRoute exact path={"/my/weekly"} component={WeeklyJdMy} />
          {/* <PrivateRoute exact path={"/usa/ogd_data"} component={OgdDataUsa} /> 
          <PrivateRoute exact path={"/usa/ftm_filter"} component={FtmDataFilterUsa} /> */}
     
          <PrivateRoute exact path={"/sharing"} component={SharingCorner} />
          <PrivateRoute exact path={"/data_check/my/daily"} component={Home} />
          <Route exact path={"/data_check/usa/daily"} component={DailyDataChcekUsa} />
          <PrivateRoute exact path={"/stock_list/my"} component={BursaList} />
          <PrivateRoute exact path={"/stock_list/usa"} component={UsaList} />
          {/* <PrivateRoute exact path={"/ogd_data"} component={OgdData} />   */}
      
          <PrivateRoute exact path={"/data_check/my/ftm_data"} component={FtmData} />
          <PrivateRoute exact path={"/data_check/usa/ftm_data"} component={FtmDataUsa} />
          <PrivateRoute exact path={"/data_check/usa/weekly"} component={WeeklyDataUsa} />
          <PrivateRoute exact path={"/daily_data"} component={DailyData} />
          <PrivateRoute exact path={"/portfolio"} component={Portfolio} />
          <PrivateRoute exact path={"/portfolio/usa"} component={PortfolioUsa} /> 
          {/* <PrivateRoute exact path={"/ftm_filter"} component={FtmDataFilter} /> */}
          
          <PrivateRoute exact path={"/blog_headline"} component={BlogHeadline} />  
          <PrivateRoute exact path={"/readinglist"} component={BlogHeadline} />  
          <PrivateRoute exact path={"/profile"} component={Profile} />  
          <PrivateRoute exact path={"/admin/user_list"} component={UserList} />  
          <PrivateRoute exact path={"/admin/in_out_list"} component={InOutLis} />  
          <PrivateRoute exact path={"/trading_journal"} component={TradingJournal} />  
          <PrivateRoute exact path={"/trading_journal/add"} component={AddNewJournal} />
          <PrivateRoute exact path={"/sharing/add"} component={AddNewSharing} />
          <PrivateRoute exact path={"/alert/my"} component={AleryMy} />
          <PrivateRoute exact path={"/admin/redirect"} component={RedirctLink} /> 
          <PrivateRoute exact path={"/docs"} component={DocList} />
          <PrivateRoute exact path={"/manage_tag"} component={ManageTag} />
          <PublicRoute exact path={"/doc/download/:file_id"} component={DocDownload} />
          
          <PrivateRoute exact path={"/admin/analytics"} component={JdAnalytic} />

          <PrivateRoute exact path={"/admin/landing_page"} component={LandingPageAdmin} />
          <PrivateRoute exact path={"/landing_page/analytics/:page_id"} component={LandingPageAnalytic} />


          <PrivateRoute exact path={"/manual_alert/:country"} component={ManualAleryMy} /> 

          <PrivateRoute exact path={"/earnings"} component={EarningPage} /> 
          <PrivateRoute exact path={"/insider_trading"} component={InsiderTrading} /> 
          
          <PrivateRoute exact path={"/pakyaportfolio"} component={Portfolio} /> 
          <PrivateRoute exact path={"/pakyaportfolio/usa"} component={PortfolioUsa} /> 

          <PrivateRoute exact path={"/zero"} component={Portfolio} /> 
          <PrivateRoute exact path={"/zero/usa"} component={PortfolioUsa} /> 
          
          <PublicRoute exact path={"/status_check/:country"} component={StatusCheck} /> 

          <PublicRoute exact path={"/gallery"} component={GalleryFolder} /> 
          <PublicRoute exact path={"/gallery/:gallery_id"} component={Gallery} /> 
          <PrivateRoute exact path={"/notes_comment"} component={SharingComment} /> 

          <PrivateRoute exact path={"/quiz/questions/:quiz_url"} component={QuizQuestion} /> 
          <PrivateRoute exact path={"/quiz/analytics/:quiz_url"} component={QuizAnalytics} /> 
          <PublicRoute exact path={"/quiz/:quiz_url"} component={QuizPage} /> 
          <PublicRoute exact path={"/quiz"} component={Quiz} /> 
          
          <PrivateRoute exact path={"/admin/user_stats"} component={UserStat} /> 
          <PrivateRoute exact path={"/notes"} component={Notes}/> 
          <PrivateRoute exact path={"/manage_tag_user"} component={ManageTagUser}/> 
          
          <Route exact path={"/subscription/canceled"} component={SubCanceled} /> 
          <Route exact path={"/req-sub"} component={SubSuccess} /> 
          
          <PrivateRoute exact path={"/data_check/my/weekly"} component={WeeklyDataMy} /> 
          <PrivateRoute exact path={"/excel_to_data"} component={ExcelData} /> 
          
          <Route exact path={"/trading_journal/:journal_id"} component={JournalDetails} />    
          <Route exact path={"/sharing/:journal_id"} component={SharingDetails} />    
          <PublicRoute restricted={true} component={LogIN} path="/login" exact />
          <PublicRoute restricted={true} component={Register} path="/register" exact />

          <Route exact path={"/subscription"} component={Subsription} />   
          <Route exact path={"/sub/:sub_id"} component={Subsription} />   
          <Route exact path={"/privacy"} component={PrivacyPolicy} />   
          <Route exact path={"/terms"} component={Terms} />   
          <Route exact path={"/reset_pass"} component={ResetPassword} /> 
          <Route exact path={"/power5"} component={Power5Check} /> 
          <Route exact path={"/power5/usa"} component={Power5CheckUsa} /> 
          <Route exact path={"/updates"} component={UpdatesPage} /> 
          <Route exact path={"/public"} component={UpdatesPage} /> 
          <Route exact path={"/updates/:tag_link"} component={UpdatesPage} /> 
          <Route exact path={"/date/:date_string"} component={UpdatesPage} /> 
          
          <Route exact path={"/forgot_password"} component={ForgotPass} /> 

          
          <Route exact path={"/404"}>
            <NotFound />
          </Route>

          <Route exact path={"*"}>
            <LandingPage />
          </Route>

          
        </Switch>
  
    </Layout>
  );
}

export default App;
