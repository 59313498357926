import { useEffect, useState, useReducer } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Spinner, Alert } from "react-bootstrap";
import Config from '../../config';
import _ from 'lodash';
import axios from "axios";
import Editor from '../editor'

const AlertModal = ({ fromUserlist = false, userData, showWNModal, setShowWNModal }) => {
    const user = useSelector((state) => state.sessionStore);
    const [alert_msg, setAlertMsg] = useState("")
    const [isShow, setIsShow] = useState(false)
    const [buttonStatus, setButtonStatus] = useState(false)
    const [showAlert, setAlertShow] = useState(false);
    const [message, setMessage] = useState()

    const [formInput, setFormInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            enable_alert: true,
            editordata: ""

        }
    );

    useEffect(() => {
        if (fromUserlist && showWNModal) {
            setIsShow(true)
        }
        if (user.token) {
            let url = "/api/user/website_alert"
            if (fromUserlist) {
                url = "/api/user/website_alert?user_id=" + userData._id
            }
            axios.get(Config.BASE_URL + url, {
                headers: {
                    "authorization": `Bearer ${user.token}`
                }
            }).then((res) => {
                if (!fromUserlist) {
                    if (res.data.show_alert) {
                        setIsShow(true)
                    }
                    setAlertMsg(res.data.alert_msg)
                }
                setFormInput({
                    enable_alert: res.data.show_alert,
                    editordata: res.data.alert_msg
                })
            })
        }
    }, [showWNModal])

    const handleClose = () => {
        setIsShow(false);
        if (fromUserlist) {
            setShowWNModal(false)
        }
    };
    const handleInput3 = evt => {
        const name = evt.target.name;
        setFormInput({ [name]: !formInput[name] });
    };

    const updateWebAlert = (e) => {
        e.preventDefault();
        setButtonStatus(true);
        let url="/api/admin/update_web_alert/" + user.user_id
        let data={...formInput}
        if (fromUserlist) {
            url="/api/admin/update_web_alert/" + userData._id
        }else{
            data.enable_alert=false
        }
        
        axios.post(Config.BASE_URL + url, data, {
            headers: {
                "authorization": `Bearer ${user.token}`
            }
        }).then((res) => {
            setButtonStatus(false);
            handleClose()
        }).catch((e) => {
            setButtonStatus(false);
            setAlertShow(true)
            setMessage(e.response.data.message)

        })
    }
    return (
        <>
            {fromUserlist === true ? <>
                <Modal show={isShow} onHide={handleClose} centered size="md" enforceFocus={false}>
                    <Modal.Header closeButton style={{ borderBottom: "0 none" }}>
                        <h5>Update Web Alert - {userData.user_name}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant="danger" show={showAlert} onClose={() => setAlertShow(false)} dismissible><p>{message}</p></Alert>
                        <form onSubmit={updateWebAlert}>
                            <div className="row" >
                                <div className="col-md-12">
                                    <input type="checkbox" className="form-check-input custom_check" checked={formInput.enable_alert} name="enable_alert" onChange={handleInput3} style={{ height: "20px" }} />
                                    <span style={{ marginLeft: "30px" }}> Show Web Alert? </span>
                                </div>

                            </div>
                            <br />
                            <div className="form-group row">
                                <div className="col-md-12 ">
                                    <Editor value={formInput.editordata} setFormInput={setFormInput} />
                                </div>
                            </div>
                            <div >
                                {buttonStatus ? (
                                    <Button
                                        variant="primary"
                                        disabled

                                    >
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Updating..
                                    </Button>
                                ) : (
                                    <Button
                                        variant="primary"
                                        type="submit"

                                    >
                                        Update
                                    </Button>
                                )}
                                <Button
                                    variant="danger"

                                    style={{ marginLeft: "10px" }}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>

                        </form>
                    </Modal.Body>

                </Modal>
            </> :
                <Modal show={isShow} centered size="md">
                    <Modal.Header  style={{ borderBottom: "0 none" }}>
                        <h5> 🔔 Web Alert ! 🔔</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert alert-warning" role="alert">
                            <div dangerouslySetInnerHTML={{ __html: alert_msg }}></div>
                        </div>
                        <div >
                            {buttonStatus ? (
                                <Button
                                    variant="primary"
                                    disabled

                                >
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Updating..
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    type="submit"
                                    onClick={updateWebAlert}
                                >
                                    OK noted!
                                </Button>
                            )}
                        </div>

                    </Modal.Body>

                </Modal>
            }
        </>
    );
};



export default AlertModal;