import { Modal, Button  } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useWindowSize } from '../../utils/custom-hooks';
import moment from 'moment';
import {Link} from 'react-router-dom'
import capitalizeWords from '../../utils/capitalizeWords'


const TagModal = ({ isShow, closeModal, currentPost,hiddenTag,setCurrentFilter,filter_list }) => {

    const [currentData,setCurrentData]=useState([])
    const windowSize = useWindowSize()
    const handleClose = () => {
        closeModal(false);
    };
    const filterTag=(data)=>{
        setCurrentFilter([{label:data.label,value:data._id}])
        closeModal(false);
    }
    function findOptionByValue(value) {
        for (const category of filter_list) {
            for (const option of category.options) {
                if (option.value == value) {
                    return option.label;
                }
            }
        }
        return null; 
    }
    useEffect(()=>{
        if(currentPost?.tag){
            let data=[...currentPost.tag]
            data.forEach((d)=>{
                let label= findOptionByValue(d._id)
                if(label){
                    d.label=label
                }else{
                    const find=hiddenTag.find((h)=>h.value===d._id)
                    if(find){
                        d.label=find?.label
                    }
                }
           
            })
  
            data.sort((a, b) => {
                const numA = parseInt(a.label?.match(/\[(\d+)\]/)[1], 10);
                const numB = parseInt(b.label?.match(/\[(\d+)\]/)[1], 10);
                if (numA !== numB) {
                    return numB - numA;
                } else {
                    return a.label.localeCompare(b.label);
                }
            });
            setCurrentData(data)
        }
    },[currentPost])
   

    return (
        <>
            <Modal show={isShow} onHide={handleClose} centered size="md">
                <Modal.Body>
                    <p className="text-center">
                        <strong>{capitalizeWords(currentPost?.title)}</strong> <br />
                        <small ><Link to={`/date/${moment(currentPost?.date).format("YYYYMMDD")}`} style={{color:'red'}}>{moment(currentPost?.date).format("DD/MM/YYYY - HH:mm A")}</Link></small>
                    </p>

                    <hr />
                    {currentData.length>0 && currentData.map((tag,i)=>{
                        return <>
                          <Button key={i} variant="light"  style={{ padding: '1em',margin:0}} block onClick={()=>filterTag(tag)}><span style={{ fontSize:`${windowSize?.width < 900?'12px':''}`}}>{tag?.label}</span></Button>  
                        </>
                    })}
                    <br/>
                    <div className="row float-left">
                        <Button
                            variant="primary"
                            type="submit"
                            className="btn btn-sm btn-primary ml-3"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default TagModal;