import { Modal, Button  } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useWindowSize } from '../../utils/custom-hooks';
import axios from "axios";
import Config from "../../config";

const TopTag = ({ isShow, closeModal,filter_list,setCurrentFilter,hiddenTag }) => {
    const [topTag,setTopTag]=useState([])
    const [randomizeArray, setRandomizeArray] = useState([])
    const [current_page,setCurrentPage]=useState(1)
    const windowSize = useWindowSize()
    const handleClose = () => {
        closeModal(false);
    };
    const filterTag=(data)=>{
        setCurrentFilter([{label:data.label,value:data._id}])
        closeModal(false);
    }

    function findOptionByValue(value) {
        for (const category of filter_list) {
            for (const option of category.options) {
                if (option.value == value) {
                    return option.label;
                }
            }
        }
        return null; 
    }
    useEffect(()=>{
        if (filter_list.length>0) {
            axios.post(Config.BASE_URL + "/api/user/random_tag",{
                randomizeArray,
                page:current_page
            }).then((res) => {
                if (res.data.data) {
                    let dataList=[]
                    res.data.data.forEach(t => {
                        let   label=findOptionByValue(t._id)
                        if(label){
                            dataList.push({
                                ...t,
                                label,
                            })
                        }else{
                            const find=hiddenTag.find((h)=>h.value===t._id)
                            if(find){
                                dataList.push({
                                    ...t,
                                    label:find.label
                                })
                            }
                        }
                    });
                    setTopTag(dataList)
                    if (randomizeArray.length === 0) {
                        setRandomizeArray(res.data.randomizeArray)
                    }
                }
            })
        }
    },[isShow,filter_list,current_page])
   
    const loadMore=()=>{
        setCurrentPage((prev_value)=>prev_value+1)
    }

    return (
        <>
            <Modal show={isShow} onHide={handleClose} centered size="md">
                <Modal.Body>
                    <p className="text-center">
                        <strong>10 RANDOM TAGS YOU MAY WANT TO STUDY</strong> <br />
                        <button className="btn btn-primary mt-1" onClick={loadMore}>Give me 10 more...</button>
                    </p>

                    <hr />
                    {topTag.length>0 && topTag.map((tag,i)=>{
                        return <>
                          <Button key={i} variant="light"  style={{ padding: '1em',margin:0}} block onClick={()=>filterTag(tag)}><span style={{ fontSize:`${windowSize?.width < 900?'12px':''}`}}>{tag?.label}</span></Button> 
                        </>
                    })}
                    <br/>
                    <div className="row float-left">
                        <Button
                            variant="primary"
                            type="submit"
                            className="btn btn-sm btn-primary ml-3"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default TopTag;