import React from "react";
import NavBar from "../navbar";
import { Container } from "react-bootstrap";
const Layout = ({ children,currentPath  }) => {

  const hideNavBar = currentPath && (
    currentPath.includes("demo1") || 
    currentPath.includes("demo2") || 
    currentPath === "/" || 
    currentPath.includes("projects")
  );
  
  return (
    <>
      {!hideNavBar && <NavBar />}
      <Container>{children}</Container>
    </>
  );
};

export default Layout;
