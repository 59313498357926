import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav ,NavDropdown } from "react-bootstrap";
import { Link ,useHistory,NavLink } from "react-router-dom";
import {useSelector ,useDispatch} from 'react-redux';
import {removeSession} from '../../store/action/sessionAction';
import { ActionTypes } from "../../store/types";
import { useWindowSize } from '../../utils/custom-hooks';
import AlertModal from '../../components/alert_modal'
const NavBar = () => {
  const  history=useHistory()
  const windowSize = useWindowSize();
  const [state, setState] = useState({
    menu: false,
    isOpen: false,
    homeLinkClass: "nav-item nav-link",
    aboutLinkClass: "nav-item nav-link",
    menuClass: "",
  });
  const [showGalleryMode,setShowGalleryMode]=useState(false)
  const [showAlert,setShowAlert]=useState(false)
  const user=useSelector((state)=>state.sessionStore);
  const current_mode=useSelector((state)=>state.dataStore.current_mode);
  const gallery_mode=useSelector((state)=>state.dataStore.gallery_mode);
  const trading_journalLink=useSelector((state)=>state.filterStore.default_trading_journal);

  
  const dispatch=useDispatch()
  const logOut=()=>{
    document.cookie = `auth_token=; max-age=0; path=/; secure;`;
    dispatch(removeSession()) 
    history.push("/login")
    closeNav()
  }
  
  const changeMode=(e)=>{
    const mode=current_mode=="MY"?"US":"MY";
    closeNav()
    dispatch({
      type:ActionTypes.SET_WEBSITE_MODE,
      payload:mode
    })
  
  }
  const changeGalleryMode=(e)=>{
    const mode=gallery_mode=="edit"?"read":"edit";
    closeNav()
    dispatch({
      type:ActionTypes.SET_GALLERY_MODE,
      payload:mode
    })
  
  }
  function openNav(evnt) {
    evnt.preventDefault()
    document.getElementById("mySidenav").style.width = "250px";
   
  }
  
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
 
  }

  const [currentUrl,setCurrentUrl]=useState(false)

  useEffect(()=>{
    if (user.role === "iamtheboss") {
      if (window.location.pathname.includes("gallery")) {
        setShowGalleryMode(true)
      } else if (window.location.pathname.includes("updates") || window.location.pathname == "/") {
        setShowGalleryMode(true)
      } else if (window.location.pathname.includes("quiz/")) {
        setShowGalleryMode(true)
      } else if (window.location.pathname.includes("admin/user_list")) {
        setShowGalleryMode(true)
      } else if (window.location.pathname.includes("sharing")) {
        setShowGalleryMode(true)
      } else {
        setShowGalleryMode(false)
      }
    } else {
      if (window.location.pathname.includes("updates") || window.location.pathname == "/") {
        setShowGalleryMode(true)
      } else {
        setShowGalleryMode(false)
      }
    }

    
    if(window.location.pathname=="/screener" || window.location.pathname=="/screener/usa"){
      setCurrentUrl(true)
    }else{
      setCurrentUrl(false)
    }
    if(window.location.pathname!=="/admin/user_list"){
      setShowAlert(true)
    }else{
      setShowAlert(false)
    }
  },[window.location.pathname,user])



  return (
    <>
      {showAlert && <AlertModal />}

      <Navbar collapseOnSelect expand="lg" style={{background:"#1c56ac"}} variant="dark">
        <Container>
          <Link to={"/"}><span className="navbar-brand">
            JerungDetector 
          </span></Link>
          
     

          <Navbar.Toggle type="button" onClick={openNav} style={{borderColor:"#1c56ac"}}/>
          {windowSize?.width>991 && <>
            <Nav className="me-auto">
            {user.token && user.expire_at > new Date().valueOf() && <>
             {/* <Nav.Link as={NavLink} to={current_mode==="MY"?"/":"/usa"}>Screener</Nav.Link> */}

             <Link to={current_mode==="MY"?"/screener":"/screener/usa"} className={currentUrl === true ? "nav-link active":"nav-link"}>Screener</Link>

             <Nav.Link as={NavLink} to={current_mode==="MY"?"/portfolio":"/portfolio/usa"}>Portfolio</Nav.Link>
             {/* <Nav.Link as={NavLink} to={current_mode==="MY"?"/zero":"/zero/usa"}>Zero</Nav.Link> */}
            
             <Nav.Link as={NavLink} to={trading_journalLink}> Trading Journal</Nav.Link> </>
            }
             {/* <Nav.Link as={NavLink} to="/sharing/61a3fd7a231a42138ff4c0bb"> Notes</Nav.Link> */}
             <Nav.Link as={NavLink} to="/updates"> Updates</Nav.Link>

             {user.token && user.expire_at > new Date().valueOf() ?<></>:<> <Nav.Link href="http://jerungdetector.com/wasap" target="_blank">Whatsapp</Nav.Link></>}
              
             {user.token && user.expire_at > new Date().valueOf() &&<>
            <Nav.Link as={NavLink} to="/notes"> Notes</Nav.Link>
             <Nav.Link as={NavLink} to={current_mode==="MY"?"/manual_alert/my":"/manual_alert/usa"}>Alert</Nav.Link>
              <Nav.Link as={NavLink} to="/gallery"> Gallery</Nav.Link>
             </>}
 
            </Nav>
            <Nav className="ml-auto">
            {user.token && user.expire_at > new Date().valueOf() && <>
            {showGalleryMode && <Nav.Link  onClick={changeGalleryMode}>   {gallery_mode=="read"?"Read":"Edit"}</Nav.Link>}
            <Nav.Link  onClick={changeMode}>   {current_mode=="MY"?"MY":"USA"}</Nav.Link> </>}
           
    

            {user.token && user.expire_at > new Date().valueOf() ?
            <>
             <Nav.Link as={NavLink} to="/profile">   {user.user_name}</Nav.Link>
    
          
              <Link
                className={state.homeLinkClass}
                onClick={logOut}
              >
                Log Out
              </Link> </> :
              <Link
                className={state.homeLinkClass}
                to="/login"
              >
                Login
              </Link>
            } 
      
            </Nav>
          </>}
        </Container>
      </Navbar>
      <div id="mySidenav" className="sidenav">
        <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>&times;</a>

        {user.token && user.expire_at > new Date().valueOf() &&<>
              <Link
                className={state.homeLinkClass}
                onClick={closeNav}
                to={current_mode==="MY"?"/screener":"/screener/usa"}
             
              >
                Screener
              </Link>
              <Link
                className={state.homeLinkClass}
                onClick={closeNav}
                to={current_mode==="MY"?"/portfolio":"/portfolio/usa"}
             
              >
                Portfolio
              </Link>
              {/* <Link
                className={state.homeLinkClass}
                onClick={closeNav}
                to={current_mode==="MY"?"/zero":"/zero/usa"}
             
              >
                Zero
              </Link> */}

              
              <Link
                className={state.homeLinkClass}
                to={trading_journalLink}
                onClick={closeNav}
              >
              Trading Journal
              </Link>
              </>}

              {/* <Link
                className={state.homeLinkClass}
                to="/sharing/61a3fd7a231a42138ff4c0bb"
                onClick={closeNav}
              >
              Notes
              </Link> */}
              <Link
                className={state.homeLinkClass}
                to="/updates"
                onClick={closeNav}
              >
              Updates
              </Link>
              {user.token && user.expire_at > new Date().valueOf() ?<></>:<> <Nav.Link href="http://jerungdetector.com/wasap">Whatsapp</Nav.Link></>}
            {user.token && user.expire_at > new Date().valueOf() &&<>
              <Link
                className={state.homeLinkClass}
                to={"/notes"}
                onClick={closeNav}
              >
                Notes
              </Link>
              <Link
                className={state.homeLinkClass}
                to={current_mode==="MY"?"/manual_alert/my":"/manual_alert/usa"}
                onClick={closeNav}
              >
                Alert
              </Link>
              <Link
                className={state.homeLinkClass}
                to="/gallery"
                onClick={closeNav}
              >
              Gallery
              </Link>
              </>
            }



              {user.token && user.expire_at > new Date().valueOf() ?
            <>
              <Link
                className={state.homeLinkClass}
                to="/profile"
                onClick={closeNav}
              >
                {user.user_name}
              </Link>

              {user.token && user.expire_at > new Date().valueOf() && <>
                {showGalleryMode && <Nav.Link  onClick={changeGalleryMode}> {gallery_mode=="read"?"Read":"Edit"}</Nav.Link>}
             <Link
              className={state.homeLinkClass}
                 onClick={changeMode}
               
              >
              {current_mode=="MY"?"MY":"USA"}
              </Link></>}


              <Link
                className={state.homeLinkClass}
                onClick={logOut}
          
              >
                Log Out
              </Link> </> :
              <Link
                className={state.homeLinkClass}
                to="/login"
                onClick={closeNav}
              >
                Login
              </Link>
            } 


   
            
            

      </div>
    </>
  );
};

export default NavBar;


