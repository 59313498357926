import React, { useState, useReducer, useEffect } from 'react';
import { Modal, Button, Spinner, Alert } from "react-bootstrap";
import axios from 'axios';
import { useSelector } from "react-redux";
import Config from '../../config';
import Select from 'react-select';
import moment from 'moment'

const formatList=[
    {label:"Article",value:"article"},
    {label:"Video",value:"video"},
    {label:"PDF",value:"pdf"},
    {label:"Gallery",value:"gallery"},
    {label:"Quiz",value:"quiz"},
    {label:"External",value:"external"},
    {label:"Facebook",value:"facebook"},
    {label:"Misc",value:"misc"},
    {label:"Contents",value:"content"},
]

const AddNewUpdate = ({updateuse_g_reqBody, isShow, closeModal, setFlag, flag, isEdit, currentPost, setIsEdit, updateValue,filter_list ,set_updateData,updateData}) => {
    const user = useSelector((state) => state.sessionStore);
    const [buttonStatus, setButtonStatus] = useState(false)
    const [showAlert, setAlertShow] = useState(false);
    const [tagList, setTags] = useState([])
    const [message, setMessage] = useState()


    const handleClose = () => {
        closeModal(false);
        setIsEdit(false)
        if (!isEdit) {
            setFormInput({ folder_name: "" })
        }
        setFormInput({
            title: "",
            sub_title: "",
            pre_title:"",
            date: moment().utcOffset(8, false).format("YYYY-MM-DDTHH:mm"),
            tag: [],
            link: "",
            image:"",
            format:[],
            is_public:false,
            show_for_tag:false,
            hide_for_tag:false,
            stock_post:false,
            jd_teaching:false,
            open_same_tab:false
        })
    };

    const [formInput, setFormInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            title: "",
            sub_title: "",
            pre_title:"",
            date: moment().utcOffset(8, false).format("YYYY-MM-DDTHH:mm"),
            tag: [],
            link: "",
            image:"",
            format:[],
            is_public:false,
            show_for_tag:false,
            hide_for_tag:false,
            stock_post:false,
            jd_teaching:false,
            open_same_tab:false
        }
    );
    const handleInput = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setFormInput({ [name]: newValue });
    };
    useEffect(() => {
        if (currentPost._id) {
            let tag_data=[]
            if(currentPost.tag.length>0){
                tag_data= currentPost.tag.map((tag)=>{return{ value: tag._id, label: tag.name }})
            }
            setFormInput({ ...currentPost,tag:tag_data,date: moment(currentPost.date).utcOffset(8, false).format("YYYY-MM-DDTHH:mm")})
        }
    }, [currentPost])

    useEffect(() => {
        if (user.token) {
            axios.get(Config.BASE_URL + "/api/admin/update_page/tag_list", {
                headers: {
                    "authorization": `Bearer ${user.token}`
                }
            }).then((res) => {
                setTags(res.data.tag_list)
            })
        }
    }, [isShow])

    const createBook = (e) => {
        e.preventDefault();
        setButtonStatus(true);
        let url = Config.BASE_URL + "/api/admin/update_page";
        if (isEdit) {
            url = Config.BASE_URL + "/api/admin/update_page/edit/" + currentPost._id
        }

        let formData = new FormData();
        formData.append('link', formInput.link);
        formData.append('title', formInput.title);
        formData.append('sub_title', formInput.sub_title);
        formData.append('pre_title', formInput.pre_title);
        formData.append('is_public', formInput.is_public);
        formData.append('show_for_tag', formInput.show_for_tag);
        formData.append('hide_for_tag', formInput.hide_for_tag);
        formData.append('stock_post', formInput.stock_post);
        formData.append('jd_teaching', formInput.jd_teaching);
        formData.append('open_same_tab', formInput.open_same_tab);
        formData.append('date', moment(formInput.date, "YYYY-MM-DDTHH:mm").utc());
        formData.append('format', JSON.stringify(formInput.format));
        if(formInput.image){
            formData.append('image', formInput.image);
        }
        
        if (formInput.tag.length > 0) {
            formInput.tag.forEach(tag => {
                formData.append('tag', tag.value);
            });
        }


        axios.post(url, formData, {
            headers: {
                "authorization": `Bearer ${user.token}`
            }
        }).then((res) => {
            if (res.data.success === true) {
                if(isEdit){
                    let old_data=[...updateData]
                    const find_data=old_data.find((d)=>d._id===currentPost._id)
                    if(find_data){
                        find_data.title=res.data?.data?.title
                        find_data.sub_title=res.data?.data?.sub_title
                        find_data.pre_title=res.data?.data?.pre_title
                        find_data.image=res.data?.data?.image
                        find_data.date= res.data?.data?.date
                        find_data.link=res.data?.data?.link
                        find_data.tag= res.data?.data?.tag
                        find_data.is_public= res.data?.data?.is_public 
                        find_data.show_for_tag=res.data?.data?.show_for_tag 
                        find_data.hide_for_tag=res.data?.data?.hide_for_tag
                        find_data.stock_post=res.data?.data?.stock_post 
                        find_data.jd_teaching=res.data?.data?.jd_teaching
                        find_data.open_same_tab=res.data?.data?.open_same_tab
                        find_data.format= res.data?.data?.format
                        setFlag(!flag)
                    }

                }else{
                    setFlag(!flag)
                    setFormInput({
                        title: "",
                        sub_title: "",
                        pre_title:"",
                        date: moment().utcOffset(8, false).format("YYYY-MM-DDTHH:mm"),
                        tag: [],
                        link: "",
                        image:"",
                        is_public:false,
                        show_for_tag:false,
                        hide_for_tag:false,
                        stock_post:false,
                        jd_teaching:false,
                        open_same_tab:false
                    })
                }

                updateuse_g_reqBody()
                setButtonStatus(false);
                handleClose()
                setIsEdit(false)
        
            } else {
                throw new Error(res.data.message)
            }

        }).catch((e) => {
            setAlertShow(true)
            setMessage(e.message)
            setButtonStatus(false);
        })
    }

    const filter_func = (value) => {
        setFormInput({ tag: value });
    }
    const change_format = (value) => {
        setFormInput({ format: value });
    }
    
    const handleImage = (e) => {

        setFormInput({ image: e.target.files[0] });
    }
    const handleInput3= evt => {
        const name = evt.target.name;
        setFormInput({ [name]: !formInput[name] });
    }

    const [inputHeight, setInputHeight] = useState('auto');
    useEffect(() => {
        const input = document.getElementById('title');
        if (input) {
            input.style.height = 'auto'; 
            input.style.height = `${input.scrollHeight}px`; 
            setInputHeight(`${input.scrollHeight}px`);
        }
    }, [formInput.title,isShow]);

    const inputStyle = {
        height: inputHeight,
        resize: 'none', 
        overflow: 'hidden',
        width: '100%', 
        border: '1px solid #ced4da', 
        borderRadius: '4px', 
        padding: '0.375rem 0.75rem', 
        lineHeight: '1.5',
    };

    const resetDate = (event) => {
        event.preventDefault(); 
        setFormInput({
            date: moment().utcOffset(8, false).format("YYYY-MM-DDTHH:mm")
        });
    };
    return (
        <>
            <Modal show={isShow} onHide={handleClose} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? "Edit" : "Add New"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="danger" show={showAlert} onClose={() => setAlertShow(false)} dismissible><p>{message}</p></Alert>
                    <form onSubmit={createBook}>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="title">Title </label>
                                <textarea
                                    id="title"
                                    onChange={handleInput}
                                    value={formInput.title}
                                    className="form-control"
                                    placeholder="Enter title"
                                    name="title"
                                    autoComplete="off"
                                    required
                                    style={inputStyle}
                                />
                            </div>
                        </div>
                        {/* <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="sub_title">Sub Title </label>
                                <input
                                    id='sub_title'
                                    type="text"
                                    onChange={handleInput}
                                    value={formInput.sub_title}
                                    className="form-control"
                                    placeholder="Enter sub_title"
                                    name="sub_title"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                        </div> */}
                         <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="pre_title">Pre-Title </label>
                                <input
                                    id='pre_title'
                                    type="text"
                                    onChange={handleInput}
                                    value={formInput.pre_title}
                                    className="form-control"
                                    placeholder="Enter pre_title"
                                    name="pre_title"
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="link">Link </label>
                                <input
                                    id='link'
                                    type="text"
                                    onChange={handleInput}
                                    value={formInput.link}
                                    className="form-control"
                                    placeholder="Enter link"
                                    name="link"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="tag">Tags</label>
                                <Select

                                    isMulti
                                    name="tag"
                                    options={tagList}
                                    isClearable={false}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select tag"
                                    onChange={filter_func}
                                    value={formInput.tag}
                                />

                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="format">Format</label>
                                <Select

                                    isMulti
                                    name="format"
                                    options={formatList}
                                    isClearable={false}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select format"
                                    onChange={change_format}
                                    value={formInput.format}
                                />

                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="date">Date <button className='btn btn-sm btn-info' onClick={resetDate}>reset to today</button></label>
                                <input
                                    id='date'
                                    type="datetime-local"
                                    onChange={handleInput}
                                    value={formInput.date}
                                    className="form-control"
                                    name="date"
                                    required
                                />
                            </div>
                        </div>
                        {/* {isEdit &&<a href={formInput.image} target='_blank'>Current image</a>}
                        <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="image">Image </label>
                                <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    accept="image/*"
                                    className='form-control'
                                    onChange={handleImage}
                                />
                            </div>
                        </div> */}
                        <div className="form-group row">
                            <div className="col-md-12">
                                <input type="checkbox" className="form-check-input custom_check" checked={formInput.is_public} name="is_public" onChange={handleInput3} style={{ height: "20px" }} />
                                <span style={{ marginLeft: "30px" }}> Make this post public </span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <input type="checkbox" className="form-check-input custom_check" checked={formInput.show_for_tag} name="show_for_tag" onChange={handleInput3} style={{ height: "20px" }} />
                                <span style={{ marginLeft: "30px" }}> Show post only if any tag selected </span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <input type="checkbox" className="form-check-input custom_check" checked={formInput.hide_for_tag} name="hide_for_tag" onChange={handleInput3} style={{ height: "20px" }} />
                                <span style={{ marginLeft: "30px" }}> Hide post if search box / tag used</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <input type="checkbox" className="form-check-input custom_check" checked={formInput.stock_post} name="stock_post" onChange={handleInput3} style={{ height: "20px" }} />
                                <span style={{ marginLeft: "30px" }}> Stocks discussions</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <input type="checkbox" className="form-check-input custom_check" checked={formInput.jd_teaching} name="jd_teaching" onChange={handleInput3} style={{ height: "20px" }} />
                                <span style={{ marginLeft: "30px" }}> JD Teaching</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <input type="checkbox" className="form-check-input custom_check" checked={formInput.open_same_tab} name="open_same_tab" onChange={handleInput3} style={{ height: "20px" }} />
                                <span style={{ marginLeft: "30px" }}> Open link in same tab </span>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            {buttonStatus ? (
                                <Button
                                    variant="primary"
                                    disabled
                                    className="btn btn-block btn-primary mb-4"
                                >
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Adding...
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="btn btn-block btn-primary mb-4"
                                >
                                    {isEdit ? "Edit" : "Add"}
                                </Button>
                            )}
                        </div>

                    </form>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default AddNewUpdate;