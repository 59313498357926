import {ActionTypes} from '../types'
const initial={
    companyList:[],
    companyListUsa:[],
    dailyData:[],
    ogdData:[],
    ftmData:[],
    date_list:new Date().toISOString(),
    date_list_usa:new Date().toISOString(),
    weekly_date:new Date().toISOString(),
    current_mode:"MY",
    data_flag:true,
    gallery_mode:"read"
}

const dataReducer=(state=initial,action)=>{
    switch(action.type){
        case ActionTypes.SET_UPDATE_DATA:
            return {...state,dailyData:action.payload}
        case ActionTypes.SET_COMPANY_LIST:
            return {...state,companyList:action.payload}
        case ActionTypes.SET_USA_COMPANY_LIST:
            return {...state,companyListUsa:action.payload}
        case ActionTypes.SET_OGD_DATA:
            return {...state,ogdData:action.payload}
        case ActionTypes.SET_FTM_DATA:
            return {...state,ftmData:action.payload}
        case ActionTypes.SET_DATE_DATA:
            return {...state,date_list:action.payload.my_date,date_list_usa:action.payload.usa_date,weekly_date:action.payload.weekly_date}
        case ActionTypes.SET_WEBSITE_MODE:
            return {...state,current_mode:action.payload}
        case ActionTypes.SET_GALLERY_MODE:
            return {...state,gallery_mode:action.payload}
        case ActionTypes.UPDATE_DATA_FLAG:
            return {...state,data_flag:action.payload}
        default:
            return state
    }
}

export default dataReducer;